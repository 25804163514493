import React from 'react';
import Navbar from './layout/Navbar';
import Landing from './layout/Landing';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

function App() {
  return (
    <Router>
    <div className="App"> 
     <Navbar />
     <Route exact path='/' component={Landing} /> 
     <section className="container">
     <Switch>
     <Route exact path='/Landing' component={Landing} /> 
     </Switch>
     </section>
    </div>
    </Router>
  );
}

export default App;
//<Route exact path='/' component={Landing} /> navigate to landing.js (/) tells react its the index/default/root page*/
//<Route exact path='/' component={Landing} /> navigate to Register.js with a switch event to switch to the specified page/route*/
