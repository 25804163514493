
import React from "react";

import Intro from './Intro';
import Features from './Features';
import WhyAdeo from './WhyAdeo';
import Testimonials from './Testimonials';
import Enterprise from './Enterprise';
import Footer from './Footer';

const Landing = () => {
    return (
        <div>
        <Intro />
        <Features />
        <WhyAdeo />
        <Testimonials />
        <Enterprise />
        <Footer />
        
    
        </div>
    )
}

export default Landing
